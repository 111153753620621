<template>

  <div class="musePage">
    <v-app id="vApp">
      
    
    <HeroBanner 
      :intent-mean-category="intentMeanCategory"
      :image-url="heroImageUrl"
      :image-credit="heroCredit"
      :parent-view="'Muse'" 
    />
    <div class="contentWrapper">
      <div class="leftRail">

        <!--left Rail-->

      </div>
      <div class="middleRail">
        
        <div class="caseStudyHolder">
          <br /><br />
          <div class="headlineDiv">
            {{ headline }}
          </div>
          <br />
          <br/>
          <div class="starDivider" ref="star">
            <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">&nbsp;&nbsp;&nbsp;
            <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">
          </div>
          <br />
          <div class="subheadline">
            {{ subheadline }}
          </div>

          <!--
          <div class="description" v-html="summary">
            {{ summary }}
          </div>
          -->


          <div class="body" v-html="body">
            {{ body }}
          </div>
          <br>
          <div class="imageGalleryContainer">
            <div class="imageContainer" v-if="content1Img">
              <img class="contentImage" :src="content1Img">
            </div>
            <div class="imageContainer" v-if="content2Img">
              <img class="contentImage" :src="content2Img">
            </div>
          </div>
          <br>

          
          


        </div>
        <NewsWall 
          :parent-view="'Muse'" 
        />


      </div>
      <div class="rightRail">
        <!--right Rail-->
      </div>
    </div>
    <VueFooter />
    </v-app>
  </div>
</template>
<script>
  import '@/plugins/vuetify'
  import adminModule from '@/store/admin'
  import searchModule from '@/store/search'
  import signinModule from '@/store/signin'
  import userModule from '@/store/user'
  import settingsModule from '@/store/settings'
  import VueFooter from '@/components/VueFooter'
  import HeroBanner from '@/components/HeroBanner'
  import NewsWall from '@/components/NewsWall'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "evolve",
    components: {
      VueFooter,
      HeroBanner,
      NewsWall
    },
    data () {
      return {
        category: null,
        intentMeanCategory: null,
        //contextCategory: null,
        //specificityLevel: null,


        //overlayType: null,
        //overlayCategory: null,
        //overlayContextCategory: null,
        //overlayParentCategory: null,
        //overlaySpecificityLevel: null,
        //overlayActionMeanCategory: null,

        //routineId: null,
        //routineType: null,

        articleSlug: null,
        articleType: null,

        headline: null,
        subheadline: null,
        //description: null,
        content1Img: null,
        content2Img: null,
        summary: null,
        body: null,

        heroImageUrl: null,
        heroCredit: null,


      }
    },
    computed: {

      ...mapGetters('auth', [
        'profile',
        'loggedIn',
        'role'
      ])

      
    },
    watch: {
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      //...mapActions('category', [
      //  'getValidCategory',
      //  'setCategoryOverlayData'
      //]),
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon',
      ]),
      //...mapActions('routine', [
      //  'getRoutineData'
      //]),
      ...mapActions('auth', [
        'setEditMode'
      ]),
      ...mapActions('admin', [
        'getArticleData'
      ])
      
      
    },
    beforeCreate () {
      if(!this.$store.state.admin) this.$store.registerModule('admin', adminModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      //if(!this.$store.state.tip) this.$store.registerModule('tip', tipModule)
      //if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      //if(!this.$store.state.category) this.$store.registerModule('category', categoryModule)

      
    },
    async created () {
      window.scrollTo(0,0)
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/muse'});
      await this.autoSignIn()
      this.setShowSearchIcon(true)
      //var routineType  = (this.$route.params.routineType) ? this.$route.params.routineType : null
      var articleSlug  = (this.$route.params.articleSlug) ? this.$route.params.articleSlug : null


      if(articleSlug) {
        this.articleSlug = articleSlug


        var articleData = await this.getArticleData(articleSlug) 
        if(articleData) {
          this.headline = articleData.articleHeadline
          this.summary = articleData.articleSummary
          this.subheadline = articleData.articleSubheadline
          this.body = articleData.articleBody
          //this.description = articleData.marketingContent.description
          this.content1Img = articleData.articleContentImage1
          this.content2Img = articleData.articleContentImage2
          this.articleType = articleData.articleType
          this.intentMeanCategory = articleData.articleIntentMeanCategory
          this.heroImageUrl = articleData.articleCalloutImageUrl
          this.heroCredit = articleData.articleCalloutImageCredit
        }
        

          

        
        
      }
    },

    mounted () {
      //this.category  = this.$route.params.catName
      this.setShowSearch(false)
      
      
    },
    updated () {
      //this.overlayTitle = "test"
    },
    
  }
</script>
<style scoped>




#vApp {
 background-color: transparent;
}
.contentWrapper {
  box-sizing: border-box;
  background-color: transparent;
}
.leftRail {
  box-sizing: border-box;
  vertical-align: top;
  background-color: transparent;
  display: none;
  min-height: 640px;  /*this setting important to prevent double scrollbars*/

}
.middleRail {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  background-color: black;
  margin: 0 auto;
  min-height: 640px; /*this setting important to prevent double scrollbars*/
}
.rightRail {
  vertical-align: top;
  box-sizing: border-box;
  background-color: transparent;
  display: none;
  min-height: 640px; /*this setting important to prevent double scrollbars*/
}
.buttonsHolder {
  background-color:  rgb(67,67,66);
  text-align: center;
  padding: 14px;
}
.addToRoutineButton {
  border: none;
  color: white;
  font-weight: 600;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
}
.button1 { 
  background-color: orange;
}
.button2 { 
  background-color: rgb(228, 228, 228);
  color: rgb(129, 129, 129);
}

.caseStudyHolder {
  background-color: white;
  min-height: 600px;
  width: 100%;
  text-align: center;
  padding: 15px;
  
}
.starDivider {
  display: inline-block;
  background-color: transparent;
  margin: 0 auto;
  width: 280px;
  padding: 5px;
}
.starImg {
  width: 30px;
  opacity: 0.8;
  display: inline-block;
}

.headlineDiv {
  font-family: 'Kreon', serif;
  font-weight: 700;
  font-size: 2.7em;
  display: inline-block;
  max-width: 650px;
}
.subheadline {
  font-family: 'Quicksand', sans-serif;
  display: inline-block;
  font-weight: 500;
  font-size: 1.9em;
  padding: 20px;
  max-width: 320px;
}
.description {
  font-family: 'Quicksand', sans-serif;
  margin: 0 auto;
  text-align: left;
  font-size: 1.3em;
  margin-top: 30px;
  max-width: 500px;
}

.body {
  font-family: 'Quicksand', sans-serif;
  margin: 0 auto;
  text-align: left;
  font-size: 1.4em;
  margin-top: 30px;
  max-width: 700px;
  padding: 20px;
}


.relatedRoutinesText {
  font-family: 'Kreon', serif;
  font-weight: 700;
  font-size: 1.6em;
  display: inline-block;
  max-width: 650px;
}

.imageGalleryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  margin: 0 auto;
}
.contentImage {
  height: 370px;
  width: 370px;
  object-fit: cover;
}



/* Muse Masonry */
img {
  max-width: 100%;
  display: block;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255,255,255,.5);
  padding: .2em .5em;
  justify-self: start;
}

.container {
  column-count: 3;
  column-gap: 10px;
}
/* Muse Masonry */



@media screen and (min-width: 360px) {
  .buttonWrapper {
    width: 100%;
  }
  .panel {
    padding: 8px;
  }
  .lineBox {
    font-size: 1em;
    min-height: 82px;
  }
  .btnIconHolder {
    font-size: 1.6em;
    padding: 2px;
  }
  .btnTitleHolder {
    padding-left: 5px;
    font-weight: 500;
  }

}


@media screen and (min-width: 400px) {
  

}
@media screen and (min-width: 500px) {
  .buttonWrapper {
    width: 80%;
  }
  .lineBox {
    font-size: 1.1em;
    padding: 14px;
    padding-top: 14px;
  }
  .panel {
    padding: 14px;
  }

  .contentImage {
    height: 250px;
    width: 250px;
    border-radius: 7px;
  }
  .imageGalleryContainer {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 2px;
    max-width: 600px;
  }
}



@media screen and (min-width: 1000px) {
  .lineBox {
    font-size: 1.3em;
    min-height: 60px;
  }
  
}


@media screen and (min-width: 1100px) {
  
  .leftRail {
    display: inline-block;
    width: 15%;
  }
  .middleRail {
    width: 70%;
  }
  .rightRail {
    display: inline-block;
    width: 15%;
  }

}


@media screen and (min-width: 1300px) {
  .leftRail {
    width: 18%;
  }
  .middleRail {
    width: 64%;
  }
  .rightRail {
    width: 18%;
  }
}


@media screen and (min-width: 1400px) {
  .leftRail {
    width: 17%;
  }
  .middleRail {
    width: 66%;
  }
  .rightRail {
    width: 17%;
  }
}





@media screen and (min-width: 1600px) {
  .leftRail {
    width: 22%;
  }
  .middleRail {
    width: 56%;
  }
  .rightRail {
    width: 22%;
  }
}
</style>